<template>
  <div class="h-100 d-flex">
    <b-container class="bg-white flex-fill p-2">
      <router-link
        :to="{ name: 'account' }"
        class="btn btn-outline-primary btn-sm"
      >
        <font-awesome-icon icon="arrow-left" />
      </router-link>
      <b-form class="mt-3" @submit.prevent="onSubmit">
        <div v-for="(data, i) in notifications" :key="i" class="mb-3">
          <h5 class="font-weight-bold">
            {{ data.label }}
          </h5>
          <div
            v-for="notification in data.notifications"
            :key="notification.name"
          >
            <b-form-checkbox
              v-model="notification.state"
              :name="notification.name"
              :value="true"
              :unchecked-value="false"
            >
              {{ notification.label }}
            </b-form-checkbox>
          </div>
        </div>
        <b-btn
          type="submit"
          class="mt-2"
          variant="primary"
          block
          :disabled="loading"
        >
          <loader v-if="loading" size="1x" />Valider
        </b-btn>
      </b-form>
    </b-container>
  </div>
</template>
<script>
import Profile from "@/mixins/profile";
export default {
  mixins: [Profile],
  data() {
    return {
      notifications: [],
    };
  },
  created() {
    this.notifications = this.me.notifications;
  },
  methods: {
    onSubmit() {
      let notifications = JSON.parse(
        JSON.stringify(this.notifications)
      ).flatMap((data) => {
        return data.notifications.map((notification) => {
          notification.via = data.via;
          delete notification.__typename;
          delete notification.label;
          return notification;
        });
      });
      this.submit({ notifications });
    },
  },
};
</script>
